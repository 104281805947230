import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

function MovieListPage() {

    const [searchTerm, setSearchTerm] = useState("");
    const [movies, setMovies] = useState([])
    const [noMoviesFound, setNoMoviesFound] = useState(false)

    useEffect(() => {
        // get movie name from local storage
        let term = localStorage.getItem("searchTerm")
        if (term) {
            fetchMovies(term)
        }
    }, [])

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    }

    const clearResults = () => {
        setMovies([]);
        setSearchTerm('');
        localStorage.removeItem("searchTerm");
        document.getElementById("searchInputBox").value = "";
    }

    const handleKeyPress = (e) => {

        if (e.keyCode === 13) {
            fetchMovies(searchTerm);
        }
    }

    const fetchMovies = (movieName) => {

        const searchURL = `https://www.omdbapi.com/?s=${movieName}&apikey=cef51bac`

        // put movie name in local storage
        localStorage.setItem("searchTerm", movieName);

        fetch(searchURL)
            .then(response => response.json())
            .then(result => {

                if (result.Error) {
                    setMovies([]);
                    setNoMoviesFound(true);
                } else {
                    setMovies(result.Search)
                    setNoMoviesFound(false);
                }

            })
    }

    const movieItems = movies.map(movie => {
        return (
            <div key={movie.imdbID}>

                <div className="col">
                    <div className="card shadow-sm">
                        <img alt="Movie poster" src={movie.Poster === "N/A" ? "/missingmovie.jpg" : movie.Poster} className="bd-placeholder-img card-img-top" />
                        <div className="card-body">
                            <h3>{movie.Title}</h3>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="btn-group">
                                    <NavLink to={`/${movie.imdbID}`}>
                                        <button type="button" className="btn btn-sm btn-outline-secondary">Details</button>
                                    </NavLink>
                                </div>
                                <small className="text-muted">{movie.Year}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div>
            <section className="py-5 text-center container">
                <div className="row py-lg-5">
                    <div className="col-lg-6 col-md-8 mx-auto">
                        <h1 className="fw-light">Movie Search</h1>
                        <p className="lead text-muted">We want to help you find the right movie.  Start a search and see what you'll be watching tonight.</p>
                        <p>
                            <input type="text" id="searchInputBox" onChange={handleSearchTermChange} onKeyPress={handleKeyPress} placeholder="ex. Batman" />
                        </p>
                        <p>
                            <button onClick={() => fetchMovies(searchTerm)} className="btn btn-primary my-2 me-3">Search</button>
                            <button onClick={() => clearResults()} className="btn btn-secondary my-2">Clear Results</button>
                        </p>
                    </div>
                </div>
            </section>

            <div className="album py-5 bg-light">
                <div className="container">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                        {movieItems}
                    </div>
                </div>
            </div>

            {noMoviesFound ? <h1>No Movies Found</h1> : null}
        </div>
    )
}

export default MovieListPage;