import { useEffect, useState } from 'react';

function MovieDetailPage(props) {

    const [movieDetail, setMovieDetail] = useState({});

    useEffect(() => {
        const imdbIdVal = props.match.params.imdbId;
        fetchMovieDetailsById(imdbIdVal);
    }, [props.match.params.imdbId])

    const fetchMovieDetailsById = (imdbId) => {

        const movieDetailsURL = `https://www.omdbapi.com/?i=${imdbId}&apikey=cef51bac`
        fetch(movieDetailsURL)
            .then(response => response.json())
            .then(result => {
                setMovieDetail(result)
            })
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col col-lg-6 mt-5">
                    <div className="text-center">
                        <img alt="Movie Poster" src={movieDetail.Poster} />
                        <h1>{movieDetail.Title}</h1>
                    </div>

                    <table className="table table-hover">

                        <tbody>
                            <tr>
                                <th scope="row">Plot</th>
                                <td>{movieDetail.Plot}</td>
                            </tr>
                            <tr>
                                <th scope="row">Released</th>
                                <td>{movieDetail.Released}</td>
                            </tr>
                            <tr>
                                <th scope="row">Director</th>
                                <td>{movieDetail.Director}</td>
                            </tr>
                            <tr>
                                <th scope="row">Writer</th>
                                <td>{movieDetail.Writer}</td>
                            </tr>
                            <tr>
                                <th scope="row">Actors</th>
                                <td>{movieDetail.Actors}</td>
                            </tr>
                            <tr>
                                <th scope="row">Awards</th>
                                <td>{movieDetail.Awards}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MovieDetailPage;
